import React, { Fragment, useContext, useEffect, useState } from "react";

import PropTypes from "prop-types";
import Header from "../Header";
// import Footer from '../Footer';
import Sidebar from "../Sidebar";
import { Layout, ConfigProvider, Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { onUpgradeModalClose } from "redux/store/billing/action";
import UpgradePlan from "components/Account/components/upgradePlan";

import NotAllowedInPlan from "components/403/NotAllowedInPlan";
import { AuthContext } from "contexts/authContext";

const LayoutBox = (props) => {
  const [silderbtn, setsilderbtn] = useState(false);
  const [isScrolled, setScroll] = useState(window.scrollY > 30);
  //
  const [darkMode, setDarkMode] = useState(false);
  const { checkRequiredLicense } = useContext(AuthContext);

  const handleScroll = () => {
    const Y = window.scrollY;
    setScroll(Y > 30);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const silderBtn = (val) => {
    setsilderbtn(val);
  };

  useEffect(() => {
    if (silderbtn === true) {
      document.body.className += " silder-on";
      document.body.classList.remove("silder-off");
    }
    return function cleanup() {
      document.body.classList.add("silder-off");
      document.body.classList.remove("silder-on");
    };
  }, [silderbtn]);

  const dispatch = useDispatch();

  const { upgradeModalOpen } = useSelector((state) => state.billing);

  const HandleTheme = (val) => {
    setDarkMode(val);
  };

  const onCloseModal = () => {
    dispatch(onUpgradeModalClose());
  };

  return (
    <Fragment>
      {/* <div className='main-panel' ref={mainPanelRef}> */} {/* </div> */}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#7b61ff",
          },
        }}
      >
        <Layout style={{ minHeight: "calc(100vh - 64px)" }}>
          <Sidebar
            silderBtn={(val) => silderBtn(val)}
            silderbtn={silderbtn}
            darkMode={darkMode}
          />

          <Fragment>
            <div className="layout_flex">
              <Header
                showBackground={isScrolled}
                silderBtn={(val) => silderBtn(val)}
                HandleTheme={(val) => HandleTheme(val)}
                darkMode={darkMode}
              />
              {checkRequiredLicense(
                `/${window.location.pathname.split("/")[1]}`
              ) ? (
                props.children
              ) : (
                <NotAllowedInPlan />
              )}
              {/* <Button onClick={handleClick}>
     Change Theme to {isDarkMode ? "Light" : "Dark"}
    </Button> */}
              {/* {subscriptionsData?.plan?.allow_change && ( */}
              <Drawer
                title="Upgrade Plan"
                width={"calc(100vw - 220px)"}
                className={"userdetail_block"}
                onClose={onCloseModal}
                open={upgradeModalOpen}
              >
                <UpgradePlan />
              </Drawer>
              {/* )} */}
            </div>
          </Fragment>
        </Layout>
        {/* <Tour open={open} onClose={() => setOpen(false)} steps={steps} /> */}
      </ConfigProvider>
    </Fragment>
  );
};

LayoutBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default LayoutBox;
