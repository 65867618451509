import * as actionTypes from "./actionTypes";

const initialState = {
  tableLoading: true,
  tableData: [],
  pagination: { total_records: 0 },
  errorMessage: "",
  hourlySessionBreakdown: null,
  hourlyActivityProductivity: null,
  hourlyActivityProductivityLoading: true,
  powerLogs: [],
  powerLogsLoading: true,
  punchLogs: [],
  punchLogsLoading: true,
  sessionAppLogs: [],
  sessionAppLogsLoading: true,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_TIMELINE_TABLE:
      return {
        ...state,
        tableLoading: true,
        errorMessage: "",
      };

    case actionTypes.FETCH_TIMELINE_TABLE_SUCCESS:
      return {
        ...state,
        tableLoading: false,
        tableData: action.payload.data || [],
        pagination: action.payload.optional?.pagination || state.pagination,
      };

    case actionTypes.FETCH_TIMELINE_TABLE_FAILED:
      return {
        ...state,
        tableLoading: false,
        errorMessage: action.payload,
      };

    case actionTypes.SET_HOURLY_ACTIVITY_PRODUCTIVITY_LOADING:
      return {
        ...state,
        hourlyActivityProductivityLoading: action.payload,
      };

    case actionTypes.SET_HOURLY_SESSION_BREAKDOWN:
      return {
        ...state,
        hourlySessionBreakdown: action.payload,
        hourlyActivityProductivityLoading: true,
        hourlyActivityProductivity: null,
        powerLogsLoading: true,
        powerLogs: [],
        errorMessage: "",
      };

    case actionTypes.FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_SUCCESS:
      return {
        ...state,
        hourlyActivityProductivity: action.payload,
        hourlyActivityProductivityLoading: false,
        errorMessage: "",
      };

    case actionTypes.FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_FAILED:
      return {
        ...state,
        hourlyActivityProductivity: null,
        hourlyActivityProductivityLoading: false,
        errorMessage: action.payload,
      };

    case actionTypes.FETCH_POWER_LOGS_SUCCESS:
      return {
        ...state,
        powerLogs: action.payload,
        powerLogsLoading: false,
        errorMessage: "",
      };

    case actionTypes.FETCH_POWER_LOGS_FAILED:
      return {
        ...state,
        powerLogs: null,
        powerLogsLoading: false,
        errorMessage: action.payload,
      };

    case actionTypes.FETCH_PUNCH_LOGS:
      return {
        ...state,
        punchLogsLoading: false,
      };

    case actionTypes.FETCH_PUNCH_LOGS_SUCCESS:
      return {
        ...state,
        punchLogs: action.payload.data,
        punchLogsLoading: false,
        errorMessage: "",
      };

    case actionTypes.FETCH_PUNCH_LOGS_FAILED:
      return {
        ...state,
        punchLogs: [],
        punchLogsLoading: false,
        errorMessage: action.payload,
      };

    case actionTypes.FETCH_SESSION_APP_LOG:
      return {
        ...state,
        sessionAppLogsLoading: true,
      };

    case actionTypes.FETCH_SESSION_APP_LOG_SUCCESS:
      return {
        ...state,
        sessionAppLogs:
          (action.payload &&
            action.payload.filter(function (app) {
              return app.event_type == "APP_LOG";
            })) ||
          [],
        sessionAppLogsLoading: false,
        errorMessage: "",
      };

    case actionTypes.FETCH_SESSION_APP_LOG_FAILED:
      return {
        ...state,
        sessionAppLogs: null,
        sessionAppLogsLoading: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
