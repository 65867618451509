import { put } from "redux-saga/effects";
import { Client } from "../../../utils/transport";
import { notification } from "antd";
import {
  workplaceSettingSuccess,
  workplaceSettingFailed,
  workplaceSettingEditSuccess,
  workplaceSettingEditFailed,
} from "./action";

export function* getWorkplaceSetting(data) {
  let config = {
    method: "GET",
    url: data.data.isOwner
      ? "ADMIN_SETTING_PHASE_1"
      : "/api/v1/me/tenant_settings/",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(workplaceSettingSuccess(response.data));
    } else {
      yield put(workplaceSettingFailed(response));
    }
  } catch (err) {
    yield put(workplaceSettingFailed(err));
  }
}

export function* editWorkplaceSetting(data) {
  const config = {
    method: "PUT",
    url: "ADMIN_SETTING_PHASE_1",
    headers: {
      ...data.data.header,
    },
    data: data.data.Bodydata,
  };
  try {
    var response = yield Client.request(config);
    if (response.status === 200) {
      yield put(workplaceSettingEditSuccess(response.data));
      notification.success({ message: "Saved succesfully!" });
    } else {
      yield put(workplaceSettingFailed(response));
    }
  } catch (err) {
    yield put(workplaceSettingFailed(err));
  }
}
