import React, { useState } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import {
  InfoCircleFilled,
  CalendarOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import {
  Table,
  Skeleton,
  Typography,
  Avatar,
  ConfigProvider,
  Progress,
  Tooltip,
  Button,
} from "antd";
import moment from "moment";
import { hms } from "../../../utils/timeConverter";
import { useSelector } from "react-redux";
import calculatePercentage from "utils/func_percentage";
const { Text, Link, Title } = Typography;

const Cardskeleton = (props) => {
  const { loading = false } = props;
  return (
    <>
      <div className="charts marginT">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "300px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const ProductivityTable = (props) => {
  const { employeeListData, duration } = props;
  const productivitydetail = useSelector((state) => state.productivitydetail);
  const [customize, setCustomize] = useState(true);
  const [loading, setLoading] = useState(true);
  const [averageToggle, setAverageToggle] = useState(false);

  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    if (name != undefined) {
      var username = name.replace(/\s+/g, " ");
      var lnt = username.toString().split(" ").length;
      var Firstname = username.split(" ")[0];
      var Lastname = lnt > 1 ? username.split(" ")[lnt - 1] : "-";
      var Av1 = Firstname[0].toString().toUpperCase();
      var Av2 =
        Lastname != undefined &&
        Lastname != " " &&
        Lastname != "" &&
        Lastname != "-"
          ? Lastname[0].toString().toUpperCase()
          : "";
      var finalName = Av1 + (Av2 != "" ? Av2 : "");

      return {
        style: {
          background: stringToColor(name),
          width: "25px",
          height: "25px",
          fontSize: "12px",
        },
        children: finalName,
      };
    }
  }

  const dataSource = [
    {
      id: "5fb1baec4697fe8f818db52d",

      totalworkingtime: 120250,
      totalonlinetime: 80000,
      totalofflinetime: 30000,
      totalproductivetime: 52122,
      totalunproductivetime: 6000,
      totalneutraltime: 25211,
      totalbreaktime: 10250,
      productiveper: 82,
    },
  ];

  const columnsTotal = [
    {
      dataIndex: "attendance",
      key: "attendance",
      // sorter: {
      //   compare: (a, b) => {},
      // },

      // sortOrder:
      //   sortedInfo.columnKey === "attendance" ? sortedInfo.orderText : "",
      width: "100px",
      title: () => (
        <div className="column_tooltip">
          Attendance
          <Tooltip title={<div>Number of days the employee was present.</div>}>
            <span className="info_i">
              <InfoCircleFilled />
            </span>
          </Tooltip>
        </div>
      ), // note it's func
    },
    {
      dataIndex: "totalonlinetime",
      title: "Online time",
      width: 150,
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.totalonlinetime - b.totalonlinetime,
      render: (value) => hms(value),
      ellipsis: {
        showTitle: false,
      },
    },

    {
      dataIndex: "totalproductivetime",
      title: "Productive time",
      width: 150,
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.totalproductivetime - b.totalproductivetime,
      render: (value) => hms(value),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "totalunproductivetime",
      title: "Unproductive time",
      width: 150,
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.totalunproductivetime - b.totalunproductivetime,
      render: (value) => hms(value),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "totalneutraltime",
      title: "Neutral time",
      width: 150,
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.totalneutraltime - b.totalneutraltime,
      render: (value) => hms(value),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "totalbreaktime",
      title: "Break time",
      width: 150,
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.totalbreaktime - b.totalbreaktime,
      render: (value) => hms(value),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "key_presses",
      title: "Key presses",
      width: 150,
      key: "key_presses",
      defaultSortOrder: "descend",
      render: (value) => {
        if (!value) {
          return 0;
        } else {
          return value;
        }
      },
    },
    {
      dataIndex: "mouse_clicks",
      title: "Mouse clicks",
      width: 150,
      key: "mouse_clicks",
      defaultSortOrder: "descend",
      render: (value) => {
        if (!value) {
          return 0;
        } else {
          return value;
        }
      },

      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "activityper",
      title: "Productivity %",
      fixed: "right",
      width: 100,
      render: (val, record) => {
        // let per = calculatePercentage(
        //   record.totalproductivetime,
        //   record.totalonlinetime
        // );

        return (
          <Progress
            percent={record?.activityper}
            style={{ height: "15px", width: "92%" }}
            status="normal"
            format={(percent) =>
              `${
                record?.activityper > 100
                  ? (record?.activityper).toFixed(2)
                  : percent.toFixed(2)
              }%`
            }
            strokeLinecap="butt"
            strokeColor={"#00B5B5"}
          />
        );
      },
    },
  ];
  const columnsAverage = [
    {
      dataIndex: "attendance",
      key: "attendance",
      // sorter: {
      //   compare: (a, b) => {},
      // },

      // sortOrder:
      //   sortedInfo.columnKey === "attendance" ? sortedInfo.orderText : "",
      width: "100px",
      title: () => (
        <div className="column_tooltip">
          Attendance
          <Tooltip title={<div>Number of days the employee was present.</div>}>
            <span className="info_i">
              <InfoCircleFilled />
            </span>
          </Tooltip>
        </div>
      ), // note it's func
    },
    {
      dataIndex: "totalonlinetime",
      title: () => (
        <div className="column_tooltip">
          Average Online time
          <Tooltip
            title={
              <div>
                online time averaged over the number of days the user was
                present in the filtered date range.
              </div>
            }
          >
            <span className="info_i">
              <InfoCircleFilled />
            </span>
          </Tooltip>
        </div>
      ), // note it's func
      width: 220,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.totalonlinetime - b.totalonlinetime,

      render: (value, extra) => {
        if (extra.attendance !== null && value !== null) {
          return value > 0 ? hms(value / extra.attendance) : hms(value);
        } else {
          return hms(0);
        }
      },
      ellipsis: {
        showTitle: false,
      },
    },

    {
      dataIndex: "totalproductivetime",
      title: () => (
        <div className="column_tooltip">
          Average Productive time
          <Tooltip
            title={
              <div>
                productive time averaged over the number of days the user was
                present in the filtered date range.
              </div>
            }
          >
            <span className="info_i">
              <InfoCircleFilled />
            </span>
          </Tooltip>
        </div>
      ), // note it's func
      width: 250,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.totalproductivetime - b.totalproductivetime,

      render: (value, extra) => {
        if (extra.attendance !== null && value !== null) {
          return value > 0 ? hms(value / extra.attendance) : hms(value);
        } else {
          return hms(0);
        }
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "totalunproductivetime",
      title: () => (
        <div className="column_tooltip">
          Average Unproductive time
          <Tooltip
            title={
              <div>
                unproductive time averaged over the number of days the user was
                present in the filtered date range.
              </div>
            }
          >
            <span className="info_i">
              <InfoCircleFilled />
            </span>
          </Tooltip>
        </div>
      ), // note it's func
      width: 270,
      defaultSortOrder: "descend",

      render: (value, extra) => {
        if (extra.attendance !== null && value !== null) {
          return value > 0 ? hms(value / extra.attendance) : hms(value);
        } else {
          return hms(0);
        }
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "totalneutraltime",
      title: () => (
        <div className="column_tooltip">
          Average Neutral time
          <Tooltip
            title={
              <div>
                neutral time averaged over the number of days the user was
                present in the filtered date range.
              </div>
            }
          >
            <span className="info_i">
              <InfoCircleFilled />
            </span>
          </Tooltip>
        </div>
      ), // note it's func
      width: 220,
      defaultSortOrder: "descend",

      render: (value, extra) => {
        if (extra.attendance !== null && value !== null) {
          return value > 0 ? hms(value / extra.attendance) : hms(value);
        } else {
          return hms(0);
        }
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "totalbreaktime",
      title: () => (
        <div className="column_tooltip">
          Average Break time
          <Tooltip
            title={
              <div>
                break time averaged over the number of days the user was present
                in the filtered date range.
              </div>
            }
          >
            <span className="info_i">
              <InfoCircleFilled />
            </span>
          </Tooltip>
        </div>
      ), // note it's func
      width: 210,
      defaultSortOrder: "descend",

      render: (value, extra) => {
        if (extra.attendance !== null && value !== null) {
          return value > 0 ? hms(value / extra.attendance) : hms(value);
        } else {
          return hms(0);
        }
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "key_presses",
      width: 200,
      key: "key_presses",
      title: () => (
        <div className="column_tooltip">
          Average key presses
          <Tooltip
            title={
              <div>
                Key presses averaged over the number of days the employee was
                present.
              </div>
            }
          >
            <span className="info_i">
              <InfoCircleFilled />
            </span>
          </Tooltip>
        </div>
      ),

      render: (value, extra) => {
        if (extra.attendance !== null && value !== null) {
          return value > 0 ? parseInt(value / extra.attendance) : value;
        } else {
          return hms(0);
        }
      },
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: "mouse_clicks",
      title: () => (
        <div className="column_tooltip">
          Average mouse clicks
          <Tooltip
            title={
              <div>
                mouse clicks averaged over the number of days the employee was
                present.
              </div>
            }
          >
            <span className="info_i">
              <InfoCircleFilled />
            </span>
          </Tooltip>
        </div>
      ),
      width: 200,
      key: "mouse_clicks",

      render: (value, extra) => {
        if (extra.attendance !== null && value !== null) {
          return value > 0 ? parseInt(value / extra.attendance) : value;
        } else {
          return hms(0);
        }
      },
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: "activityper",
      title: "Productivity %",
      fixed: "right",
      width: 100,

      render: (val, record) => {
        // let per = calculatePercentage(
        //   record.totalproductivetime,
        //   record.totalonlinetime
        // );

        return (
          <Progress
            percent={record?.activityper}
            style={{ height: "15px", width: "92%" }}
            status="normal"
            format={(percent) =>
              `${
                record?.activityper > 100
                  ? record?.activityper.toFixed(2)
                  : percent.toFixed(2)
              }%`
            }
            strokeLinecap="butt"
            strokeColor={"#00B5B5"}
          />
        );
      },
    },
  ];
  const toggleAverage = (e) => {
    e.preventDefault();
    setAverageToggle(!averageToggle);
  };
  return (
    <>
      <div className="charts ">
        <div className="chart_header ml-2">
          {productivitydetail &&
          productivitydetail.productivity_employee_detailed_employee_list_processing ===
            true ? (
            <Skeleton.Input
              size={"small"}
              shape={"default"}
              style={{ height: "15px" }}
            />
          ) : (
            <Title level={2}>Detail </Title>
          )}

          <Button
            size="defult"
            icon={<LineChartOutlined />}
            onClick={toggleAverage}
          >
            {averageToggle ? "Total" : "Average"}
          </Button>
        </div>
        <ConfigProvider renderEmpty={customize ? null : undefined}>
          <div className=" " style={{ marginTop: "-10px" }}>
            <Table
              // dataSource={dataSource}
              dataSource={employeeListData && employeeListData}
              // columns={columns}
              columns={averageToggle ? columnsAverage : columnsTotal}
              pagination={false}
              size="small"
              scroll={{
                x: 1500,
                // y: 300,
              }}
              rowKey="id"
            />
          </div>
        </ConfigProvider>
      </div>
    </>
  );
};

ProductivityTable.propTypes = {
  getList: PropTypes.func,
};

export default ProductivityTable;
