export const USER_CREATE_INITIAL = "USER_CREATE_INITIAL";
export const USER_CREATE_PROCESS = "USER_CREATE_PROCESS";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAILED = "USER_CREATE_FAILED";

export const USER_LIST_INITIAL = "USER_LIST_INITIAL";
export const USER_LIST_PROCESS = "USER_LIST_PROCESS";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILED = "USER_LIST_FAILED";
export const USER_LIST_SEARCH = "USER_LIST_SEARCH";
export const USER_LIST_SEARCH_INITIAL = "USER_LIST_SEARCH_INITIAL";

export const FIELD_USER_LIST_INITIAL = "FIELD_USER_LIST_INITIAL";
export const FIELD_USER_LIST_PROCESS = "FIELD_USER_LIST_PROCESS";
export const FIELD_USER_LIST_SUCCESS = "FIELD_USER_LIST_SUCCESS";
export const FIELD_USER_LIST_FAILED = "FIELD_USER_LIST_FAILED";

export const USER_UPDATE_INITIAL = "USER_UPDATE_INITIAL";
export const USER_UPDATE_PROCESS = "USER_UPDATE_PROCESS";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILED = "USER_UPDATE_FAILED";

export const USER_INFO_UPDATE_INITIAL = "USER_INFO_UPDATE_INITIAL";
export const USER_INFO_UPDATE_PROCESS = "USER_INFO_UPDATE_PROCESS";
export const USER_INFO_UPDATE_SUCCESS = "USER_INFO_UPDATE_SUCCESS";
export const USER_INFO_UPDATE_FAILED = "USER_INFO_UPDATE_FAILED";

export const USER_INVITE_CREATE_INITIAL = "USER_INVITE_CREATE_INITIAL";
export const USER_INVITE_CREATE_PROCESS = "USER_INVITE_CREATE_PROCESS";
export const USER_INVITE_CREATE_SUCCESS = "USER_INVITE_CREATE_SUCCESS";
export const USER_INVITE_CREATE_FAILED = "USER_INVITE_CREATE_FAILED";

export const ROLE_USER_LIST_INITIAL = "ROLE_USER_LIST_INITIAL";
export const ROLE_USER_LIST_PROCESS = "ROLE_USER_LIST_PROCESS";
export const ROLE_USER_LIST_SUCCESS = "ROLE_USER_LIST_SUCCESS";
export const ROLE_USER_LIST_FAILED = "ROLE_USER_LIST_FAILED";

export const ROLE_USER_UPDATE_INITIAL = "ROLE_USER_UPDATE_INITIAL";
export const ROLE_USER_UPDATE_PROCESS = "ROLE_USER_UPDATE_PROCESS";
export const ROLE_USER_UPDATE_SUCCESS = "ROLE_USER_UPDATE_SUCCESS";
export const ROLE_USER_UPDATE_FAILED = "ROLE_USER_UPDATE_FAILED";

//* ME DETAILS START*/
export const ME_DETAILS_INITIAL = "ME_DETAILS_INITIAL";
export const ME_DETAILS_PROCESS = "ME_DETAILS_PROCESS";
export const ME_DETAILS_SUCCESS = "ME_DETAILS_SUCCESS";
export const ME_DETAILS_FAILED = "ME_DETAILS_FAILED";

export const ME_DETAILS_RECHECK_INITIAL = "ME_DETAILS_RECHECK_INITIAL";
export const ME_DETAILS_RECHECK_PROCESS = "ME_DETAILS_RECHECK_PROCESS";
export const ME_DETAILS_RECHECK_SUCCESS = "ME_DETAILS_RECHECK_SUCCESS";
export const ME_DETAILS_RECHECK_FAILED = "ME_DETAILS_RECHECK_FAILED";

export const TEAM_WISE_USER_LIST_INITIAL = "TEAM_WISE_USER_LIST_INITIAL";
export const TEAM_WISE_USER_LIST_PROCESS = "TEAM_WISE_USER_LIST_PROCESS";
export const TEAM_WISE_USER_LIST_SUCCESS = "TEAM_WISE_USER_LIST_SUCCESS";
export const TEAM_WISE_USER_LIST_FAILED = "TEAM_WISE_USER_LIST_FAILED";

export const SET_UPLOAD_AVATAR_INITIAL = "SET_UPLOAD_AVATAR_INITIAL";
export const SET_UPLOAD_AVATAR_PROCESS = "SET_UPLOAD_AVATAR_PROCESS";
export const SET_UPLOAD_AVATAR_SUCCESS = "SET_UPLOAD_AVATAR_SUCCESS";
export const SET_UPLOAD_AVATAR_FAILED = "SET_UPLOAD_AVATAR_FAILED";

export const GET_USER_IDS_INITIAL = "GET_USER_IDS_INITIAL";
export const GET_USER_IDS_PROCESS = "GET_USER_IDS_PROCESS";
export const GET_USER_IDS_SUCCESS = "GET_USER_IDS_SUCCESS";
export const GET_USER_IDS_FAILED = "GET_USER_IDS_FAILED";

export const FIELD_USER_BY_ID_INITIAL = "FIELD_USER_BY_ID_INITIAL";
export const FIELD_USER_BY_ID_PROCESS = "FIELD_USER_BY_ID_PROCESS";
export const FIELD_USER_BY_ID_SUCCESS = "FIELD_USER_BY_ID_SUCCESS";
export const FIELD_USER_BY_ID_FAILED = "FIELD_USER_BY_ID_FAILED";

export const FIELD_USER_LAST_LOCATION_INITIAL =
  "FIELD_USER_LAST_LOCATION_INITIAL";
export const FIELD_USER_LAST_LOCATION_PROCESS =
  "FIELD_USER_LAST_LOCATION_PROCESS";
export const FIELD_USER_LAST_LOCATION_SUCCESS =
  "FIELD_USER_LAST_LOCATION_SUCCESS";
export const FIELD_USER_LAST_LOCATION_FAILED =
  "FIELD_USER_LAST_LOCATION_FAILED";

export const USER_BULK_IMPORT_VALIDATE_INITIAL =
  "USER_BULK_IMPORT_VALIDATE_INITIAL";
export const USER_BULK_IMPORT_VALIDATE_PROCESS =
  "USER_BULK_IMPORT_VALIDATE_PROCESS";
export const USER_BULK_IMPORT_VALIDATE_SUCCESS =
  "USER_BULK_IMPORT_VALIDATE_SUCCESS";
export const USER_BULK_IMPORT_VALIDATE_FAILED =
  "USER_BULK_IMPORT_VALIDATE_FAILED";

export const USER_BULK_IMPORT_INITIAL = "USER_BULK_IMPORT_INITIAL";
export const USER_BULK_IMPORT_PROCESS = "USER_BULK_IMPORT_PROCESS";
export const USER_BULK_IMPORT_SUCCESS = "USER_BULK_IMPORT_SUCCESS";
export const USER_BULK_IMPORT_FAILED = "USER_BULK_IMPORT_FAILED";

export const USER_BULK_IMPORT_GET_JOB_STATUS_INITIAL =
  "USER_BULK_IMPORT_GET_JOB_STATUS_INITIAL";
export const USER_BULK_IMPORT_GET_JOB_STATUS_PROCESS =
  "USER_BULK_IMPORT_GET_JOB_STATUS_PROCESS";
export const USER_BULK_IMPORT_GET_JOB_STATUS_SUCCESS =
  "USER_BULK_IMPORT_GET_JOB_STATUS_SUCCESS";
export const USER_BULK_IMPORT_GET_JOB_STATUS_FAILED =
  "USER_BULK_IMPORT_GET_JOB_STATUS_FAILED";

export const USER_BULK_IMPORT_LOGS_INITIAL = "USER_BULK_IMPORT_LOGS_INITIAL";
export const USER_BULK_IMPORT_LOGS_PROCESS = "USER_BULK_IMPORT_LOGS_PROCESS";
export const USER_BULK_IMPORT_LOGS_SUCCESS = "USER_BULK_IMPORT_LOGS_SUCCESS";
export const USER_BULK_IMPORT_LOGS_FAILED = "USER_BULK_IMPORT_LOGS_FAILED";
export const USER_NEW_LIST_INITIAL = "USER_NEW_LIST_INITIAL";
export const USER_NEW_LIST_PROCESS = "USER_NEW_LIST_PROCESS";
export const USER_NEW_LIST_SUCCESS = "USER_NEW_LIST_SUCCESS";
export const USER_NEW_LIST_FAILED = "USER_NEW_LIST_FAILED";

export const USER_SEARCH_LIST_INITIAL = "USER_SEARCH_LIST_INITIAL";
export const USER_SEARCH_LIST_PROCESS = "USER_SEARCH_LIST_PROCESS";
export const USER_SEARCH_LIST_SUCCESS = "USER_SEARCH_LIST_SUCCESS";
export const USER_SEARCH_LIST_FAILED = "USER_SEARCH_LIST_FAILED";

export const DEACTIVATE_USERS = "DEACTIVATE_USERS";
export const DEACTIVATE_USERS_COMPLETE = "DEACTIVATE_USERS_COMPLETE";

export const ACTVATE_USERS = "ACTVATE_USERS";
export const ACTVATE_USERS_COMPLETE = "ACTVATE_USERS_COMPLETE";

export const EXPORT_USER_LIST_INITIAL = "EXPORT_USER_LIST_INITIAL";
export const EXPORT_USER_LIST_PROCESS = "EXPORT_USER_LIST_PROCESS";
export const EXPORT_USER_LIST_SUCCESS = "EXPORT_USER_LIST_SUCCESS";
export const EXPORT_USER_LIST_FAILED = "EXPORT_USER_LIST_FAILED";

export const FETCH_ME_INFO = "FETCH_ME_INFO";
export const FETCH_ME_INFO_SUCCESS = "FETCH_ME_INFO_SUCCESS";
export const FETCH_ME_INFO_FAILED = "FETCH_ME_INFO_FAILED";

export const FETCH_ME_INFO_RECHECK = "FETCH_ME_INFO_RECHECK";

export const FETCH_ME_ROLE_ASSIGNMENT_SUCCESS =
  "FETCH_ME_ROLE_ASSIGNMENT_SUCCESS";
export const FETCH_ME_ROLE_ASSIGNMENT_FAILED =
  "FETCH_ME_ROLE_ASSIGNMENT_FAILED";

export const BULK_USER_TEAM_UPDATE = "BULK_USER_TEAM_UPDATE";
export const BULK_USER_TEAM_UPDATE_SUCCESS = "BULK_USER_TEAM_UPDATE_SUCCESS";
export const BULK_USER_TEAM_UPDATE_FAILED = "BULK_USER_TEAM_UPDATE_FAILED";
