import { put } from "redux-saga/effects";
import { LogsReportAction } from "../../action";
import { Client } from "../../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { ExportCSV } from "utils/export-csv";
import dayjs from "dayjs";
import { hms } from "utils/timeConverter";

const ExportData = (fileName, data, colunms, filterDate, teams) => {
  let result = [];
  const Generated = "Generated Date:-" + dayjs().format("DD-MM-YYYY hh:mm a");
  const generatedData = [
    fileName,
    "Teams: - " + teams.join(",").replaceAll(",", "/"),
    "Date:-" + filterDate,

    Generated,
    colunms.join(),
  ];

  data.forEach((record) => {
    const addColunmsValues = [
      record?.first_name + " " + record?.last_name,
      record?.employee_id,
      record?.team_name,
      record?.application_name,
      record?.url,
      record?.title.replaceAll(",", " "),
      record?.start_time,
      record?.end_time,
      hms(record?.duration),
      record?.app_policy_status,
      hms(record?.active_time),
      hms(record?.idle_time),
      record?.keystroke_count,
      record?.mouse_clicks,
      record?.system_status,
    ];

    generatedData.push(addColunmsValues.join(","));
  });
  result = generatedData;
  return result;
};
const getDownloadCSV = (payload, data) => {
  const teamIds = payload.payload.body.group_id.split(",");
  const selectTeamNames = payload.payload.team_list_data
    .filter((item) => teamIds.includes(item.id))
    .map((item) => item.name);

  const csvdata = data?.data;
  const date =
    dayjs(payload?.payload.body.start_date).format("YYYY-MM-DD") +
    " To " +
    dayjs(payload?.payload.body.end_date).format("YYYY-MM-DD");

  const colunms = [
    "Employee",
    "Employee ID",
    "Team",
    "Application",
    "URL",
    "Title",
    "Start time",
    "End time",
    "Duration",
    "Mapping status",
    "Active time",
    "Idle time",
    "Key presses",
    "Mouse clicks",
    "System status",
  ];
  const exdata = ExportData(
    "Logs Report List",
    csvdata,
    colunms,
    date,
    selectTeamNames
  );

  ExportCSV("Logs_report_List_", exdata);
};
export function* fetchLogsReportSaga(action) {
  const config = {
    method: "POST",
    url: `query/reports/app_log_report`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    yield put(LogsReportAction.fetchLogsReportSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(LogsReportAction.fetchLogsReportFailed(errorMessage));
  }
}

export function* downloadLogsReportSaga(action) {
  const config = {
    method: "POST",
    url: `query/reports/app_log_report`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    getDownloadCSV(action, response.data);
    yield put(LogsReportAction.downloadLogsReportSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(LogsReportAction.downloadLogsReportFailed(errorMessage));
  }
}
