export const FETCH_MANUALTIME = "FETCH_MANUALTIME";
export const FETCH_MANUALTIME_SUCCESS = "FETCH_MANUALTIME_SUCCESS";
export const FETCH_MANUALTIME_FAILED = "FETCH_MANUALTIME_FAILED";

export const CREATE_MANUALTIME = "CREATE_MANUALTIME";
export const CREATE_MANUALTIME_SUCCESS = "CREATE_MANUALTIME_SUCCESS";
export const CREATE_MANUALTIME_FAILED = "CREATE_MANUALTIME_FAILED";

export const UPDATE_MANUALTIME = "UPDATE_MANUALTIME";
export const UPDATE_MANUALTIME_SUCCESS = "UPDATE_MANUALTIME_SUCCESS";
export const UPDATE_MANUALTIME_FAILED = "UPDATE_MANUALTIME_FAILED";

export const SET_MANUALTIME_ERROR_MESSAGE = "SET_MANUALTIME_ERROR_MESSAGE";

export const DELETE_MANUALTIME = "DELETE_MANUALTIME";
export const DELETE_MANUALTIME_SUCCESS = "DELETE_MANUALTIME_SUCCESS";
export const DELETE_MANUALTIME_FAILED = "DELETE_MANUALTIME_FAILED";
