import React, { useState, useEffect } from "react";
import "../index.scss";
import { Divider, TreeSelect } from "antd";

const { SHOW_PARENT } = TreeSelect;

const TeamTree = (props) => {
  const {
    datateam,
    isReload,
    selectTeam,
    initialTeamIds,
    selectedTeamIds,
    selectTeamParent,
    allTeamSelect,
    setAllTeamSelect,
  } = props || {};
  const [value, setValue] = useState(initialTeamIds);

  const handleClear = () => {
    setValue([]);
    selectTeam([]);
    setAllTeamSelect(false);
  };

  useEffect(() => {
    if (isReload) {
      setValue([]);
      setAllTeamSelect(false);
    }
  }, [isReload]);

  const onChange = (newValue) => {
    setValue(newValue);
    selectTeamParent(newValue);
  };

  const handleaAllSelect = () => {
    setValue(datateam);
    selectTeamParent([]);
    setAllTeamSelect(true);
    selectTeam([]);
  };

  const getKeyElementValues = (obj) => {
    let values = [];

    if (obj.value) {
      values.push(obj.value);
    }

    for (const value in obj) {
      if (typeof obj[value] === "object") {
        values = values.concat(getKeyElementValues(obj[value]));
      }
    }

    return values;
  };

  const onDeselect = (value, options) => {
    let deselectIds = [];
    deselectIds = getKeyElementValues(options);
    const newIds = (
      allTeamSelect
        ? datateam.reduce(
            (acc, teamNode) => [...acc, ...getKeyElementValues(teamNode)],
            []
          )
        : selectedTeamIds
    ).filter((x) => deselectIds.indexOf(x) === -1);
    selectTeam(newIds);
    setAllTeamSelect(false);
  };

  const onSelect = (value, options) => {
    let selectIds = [];
    selectIds = getKeyElementValues(options);
    const filteredIds = selectedTeamIds.filter(
      (x) => selectIds.indexOf(x) === -1
    );
    selectTeam([...filteredIds, ...selectIds]);
    setAllTeamSelect(false);
  };

  const dropdownRender = (menus) => (
    <div>
      <div className="d_filter">
        <div className="d_hover" onClick={handleaAllSelect}>
          {" "}
          Select all
        </div>
        <div className="d_hover" onClick={handleClear}>
          Clear
        </div>
      </div>

      <Divider
        style={{
          margin: "0px",
          marginBottom: "5px",
        }}
      />
      {menus}
    </div>
  );
  return (
    datateam && (
      <TreeSelect
        allowClear
        autoClearSearchValue={false}
        treeData={datateam}
        value={value}
        onChange={onChange}
        onSelect={onSelect}
        onDeselect={onDeselect}
        treeCheckable={true}
        showCheckedStrategy={SHOW_PARENT}
        placeholder={"All Teams"}
        showSearch={true}
        showArrow={true}
        maxTagCount="responsive"
        style={{
          minWidth: "180px",
        }}
        onClear={handleClear}
        dropdownRender={dropdownRender}
        filterTreeNode={(input, option) =>
          // @ts-ignore
          (option.title || "").toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
    )
  );
};

export default TeamTree;
