import { put } from "redux-saga/effects";
import { ScreenshotActions } from "../action";
import { Client, RawHttpClient } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import CryptoJS from "crypto-js";

export function* fetchScreenshotTableSaga(data) {
  const config = {
    method: "POST",
    url: `query/timeline/employee_timeline_details`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    data: data.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(ScreenshotActions.fetchScreenshotTableSuccess(response.data));
    } else {
      yield put(
        ScreenshotActions.fetchScreenshotTableFailed(SOMETHING_WENT_WRONG)
      );
    }
  } catch (err) {
    console.error(err);
    yield put(
      ScreenshotActions.fetchScreenshotTableFailed(SOMETHING_WENT_WRONG)
    );
  }
}

export function* fetchHourlyActivityProductivitySaga(data) {
  const config = {
    method: "POST",
    url: `query/timeline/hourly_activity_productivity`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    data: data.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      if (response.data.data?.length > 0) {
        yield put(
          ScreenshotActions.fetchHourlyActivityProductivitySuccess(
            response.data.data[0]
          )
        );
      } else {
        yield put(
          ScreenshotActions.setHourlyActivityProductivityLoading(false)
        );
      }
    } else {
      yield put(
        ScreenshotActions.fetchHourlyActivityProductivityFailed(
          SOMETHING_WENT_WRONG
        )
      );
    }
  } catch (err) {
    console.error(err);
    yield put(
      ScreenshotActions.fetchHourlyActivityProductivityFailed(
        SOMETHING_WENT_WRONG
      )
    );
  }
}

export function* fetchPowerLogsSaga(data) {
  const config = {
    method: "POST",
    url: `query/timeline/hourly_power_log`,
    headers: {
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    data: data.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    yield put(ScreenshotActions.fetchPowerLogsSuccess(response.data.data));
  } catch (err) {
    console.error(err);
    yield put(ScreenshotActions.fetchPowerLogsFailed(SOMETHING_WENT_WRONG));
  }
}

export function* fetchSessionAppLogsSaga(data) {
  const config = {
    method: "POST",
    url: `query/timeline/timeline_breakdown_details`,
    headers: {
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    data: data.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    yield put(ScreenshotActions.fetchSessionAppSuccess(response.data.data));
  } catch (err) {
    console.error(err);
    yield put(ScreenshotActions.fetchSessionAppFailed(SOMETHING_WENT_WRONG));
  }
}

export function* fetchSecreenshotListByUserSaga(data) {
  const config = {
    method: "POST",
    url: `query/timeline/screenshot_log`,
    headers: {
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    data: data.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.data.status) {
      yield put(
        ScreenshotActions.fetchScreenshotListByUserIdSuccess(response?.data)
      );
    } else {
      yield put(
        ScreenshotActions.fetchScreenshotListByUserIdFailed(
          response.data?.error_code
        )
      );
    }
  } catch (err) {
    console.error("err", err);
    yield put(
      ScreenshotActions.fetchScreenshotListByUserIdFailed(SOMETHING_WENT_WRONG)
    );
  }
}

const decryptImageBlob = (ciphertext, key, iv) => {
  const blob = new Blob([ciphertext], {
    type: "application/octet-stream",
  });

  const reader = new FileReader();

  reader.addEventListener("loadend", (e) => {
    const ciphertext = e.srcElement.result;

    const decodedString = CryptoJS.enc.Base64.parse(ciphertext);

    const keyWA = CryptoJS.enc.Utf8.parse(key);

    const ivWA = CryptoJS.enc.Utf8.parse(iv);

    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: decodedString },
      keyWA,
      {
        iv: ivWA,
      }
    );

    const final = CryptoJS.enc.Base64.stringify(decrypted);

    window.open(`data:application/octet-stream;base64,${final}`, "_blank");
  });

  reader.readAsText(blob);
};

export function* downloadScreenshotSaga(action) {
  const config = {
    method: "GET",
    url: `api/v1/analytics/screenshots/${action.payload.identityId}/single/download/?imageId=${action.payload.imagePath}`,
    headers: {
      ...action.payload.headers,
    },
    maxRedirects: 0,
  };
  try {
    const response = yield Client.request(config);

    if (
      response.data.data.encrypted ||
      response.data.data.key?.enableEncryption
    ) {
      const blobResponse = yield RawHttpClient.request({
        method: "GET",
        url: response.data.data.url,
        fetchPolicy: "no-cache",
        responseType: "blob",
      });

      decryptImageBlob(
        blobResponse.data,
        response.data.data.key.encryptionKey,
        response.data.data.iv
      );
    } else {
      window.open(`${response.data.data.url}`, "_blank");
    }
    yield put(ScreenshotActions.downloadScreenshotSuccess());
  } catch (err) {
    console.log(err);
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ScreenshotActions.downloadScreenshotFailed(errorMessage));
  }
}

export function* downloadScreenshotsZipSaga(action) {
  try {
    window.open(
      `${action.payload.coreApiUrl}/api/v1/analytics/screenshots/${action.payload.identityId}/day/${action.payload.date}/download/`,
      "_blank"
    );

    yield put(ScreenshotActions.downloadScreenshotsZipSuccess());
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ScreenshotActions.downloadScreenshotsZipFailed(errorMessage));
  }
}

export function* flagScreenshotSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/analytics/screenshots/flagged/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };

  try {
    const response = yield Client.request(config);

    yield put(
      ScreenshotActions.getFlaggedScreenshot({
        identityId: action.payload.body.source.id,
        headers: action.payload.headers,
      })
    );
    yield put(ScreenshotActions.flagScreenshotSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ScreenshotActions.flagScreenshotFailed(errorMessage));
  }
}

export function* unflagScreenshotSaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/analytics/screenshots/flagged/?imageId=${action.payload.imagePath}`,
    headers: {
      ...action.payload.headers,
    },
  };

  try {
    const response = yield Client.request(config);

    yield put(
      ScreenshotActions.getFlaggedScreenshot({
        identityId: action.payload.identityId,
        headers: action.payload.headers,
      })
    );

    yield put(ScreenshotActions.unflagScreenshotSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ScreenshotActions.unflagScreenshotFailed(errorMessage));
  }
}

export function* getFlaggedScreenshotSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/analytics/screenshots/flagged/for-user/${action.payload.identityId}/`,
    headers: {
      ...action.payload.headers,
    },
  };

  try {
    const response = yield Client.request(config);

    yield put(ScreenshotActions.getFlaggedScreenshotSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ScreenshotActions.getFlaggedScreenshotFailed(errorMessage));
  }
}
