/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";

import { Table, Empty, Spin, Drawer, Card, Tooltip, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { hm } from "utils/timeConverter";
import { EyeOutlined } from "@ant-design/icons";
import { AttendanceDetailedAction } from "redux/store/action";
import GetParamers from "utils/getParamers";
import { AuthContext } from "contexts/authContext";
import AttendaceCalender from "../../Attendance/components/attendacecalender";
import UserIO from "../../../assets/images/userIO.svg";
import SystemIO from "../../../assets/images/systemIO.svg";
import ClientAuto from "../../../assets/images/idle-punch-out.svg";
import _ from "lodash";
import dayjs from "dayjs";
const { Title } = Typography;

const Attendancetable = (props) => {
  const attendancedetailed = useSelector((state) => state.attendancedetailed);
  const [AttendanceListData, setAttendanceListData] = useState([]);
  const { KState } = useContext(AuthContext);

  const dispatch = useDispatch();
  const [EmployeeDetail, setEmployeeDetail] = useState();

  const groupIds = [];
  useEffect(() => {
    if (
      attendancedetailed.attendance_detailed_attendance_trend_success === true
    ) {
      if (
        _.size(attendancedetailed.attendance_detailed_attendance_trend_data) > 0
      ) {
        if (
          _.size(
            attendancedetailed.attendance_detailed_attendance_trend_data[0].logs
          ) > 0
        ) {
          const data = [];
          attendancedetailed.attendance_detailed_attendance_trend_data[0].logs.forEach(
            (item) => {
              if (item.date !== null) {
                data.push(item);
              }
            }
          );
          const AppTotal = _.orderBy(data, "date", "desc");
          setAttendanceListData(AppTotal);
        }
      }
    }
    if (
      attendancedetailed.attendance_detailed_attendance_trend_failed === true
    ) {
      setAttendanceListData([]);
    }
  }, [attendancedetailed.attendance_detailed_attendance_trend_success]);

  const handleOk = () => {
    setTimeout(() => {
      setOpen(false);
    }, 3000);
    dispatch(
      AttendanceDetailedAction.AttendanceDetailedEmployeePunchLogListInitial()
    );
  };
  const handleCancel = () => {
    setOpen(false);
    dispatch(
      AttendanceDetailedAction.AttendanceDetailedEmployeePunchLogListInitial()
    );
  };

  const openPunchLogDateChange = (data) => {
    setOpen(true);
    const userData = {
      dataemployee: {
        identity_id: props.identityId,
      },
      date: data,
    };
    setEmployeeDetail(userData);

    if (data !== undefined) {
      dispatch(
        AttendanceDetailedAction.AttendanceDetailedEmployeePunchLogListProcess(
          // datacurrent
          KState.keycloak.token,
          GetParamers(data, data, groupIds, [props.identityId])
        )
      );
    }
  };

  const [open, setOpen] = useState(false);

  const columns = [
    // {
    //   title: "Total Attendance",
    //   dataIndex: "present_days",
    //   fixed: "left",
    //   sorter: {
    //     compare: (a, b) => a.chinese - b.chinese,
    //     multiple: 3,
    //   },
    //   width: 80,
    // },
    {
      title: "Date",
      dataIndex: "date",
      // sorter: {
      //   compare: (a, b) => a.chinese - b.chinese,
      //   multiple: 3,
      // },
      render: (val, record) => {
        return record.date != null
          ? moment(record.date).format("DD/MMM/YYYY")
          : "";
      },
    },
    {
      title: "In",
      dataIndex: "punch_in",
      // sorter: {
      //   compare: (a, b) => a.chinese - b.chinese,
      //   multiple: 3,
      // },
      render: (val, record) => {
        // return record.punch_in != null
        //   ? moment(record.punch_in).format("hh:mm A")
        //   : "";
        return record && record.punch_in != null ? (
          <div className="pin">
            {record?.email?.includes(".local") ? (
              <Tooltip
                title={
                  "SYSTEM" + (record?.platform ? " | " + record?.platform : "")
                }
              >
                <img
                  src={SystemIO}
                  alt="SystemIO"
                  className="max-w-5 max-h-5"
                />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  "USER" + (record?.platform ? " | " + record?.platform : "")
                }
              >
                <img src={UserIO} alt="UserIO" className="max-w-5 max-h-5" />
              </Tooltip>
            )}
            <span className="mr-2">
              {dayjs(record.punch_in).format("hh:mm A")}
            </span>
          </div>
        ) : null;
      },
    },
    {
      title: "Out",
      dataIndex: "punch_out",
      // sorter: {
      //   compare: (a, b) => a.chinese - b.chinese,
      //   multiple: 3,
      // },
      render: (val, record) => {
        // return record.punch_out != null
        //   ? moment(record.punch_out).format("hh:mm A")
        //   : "";
        return record && record.punch_out != null ? (
          <div className="pout">
            {record?.out_tracker?.mode === "UNSPECIFIED" ||
            record?.out_tracker?.mode === "MANUAL" ? (
              <Tooltip
                title={
                  "USER" + (record?.platform ? " | " + record?.platform : "")
                }
              >
                <img src={UserIO} alt="UserIO" className="max-w-5 max-h-5" />
              </Tooltip>
            ) : record?.out_tracker?.mode === "SERVER_AUTO" ? (
              <Tooltip
                title={
                  "SYSTEM" + (record?.platform ? " | " + record?.platform : "")
                }
              >
                <img
                  src={SystemIO}
                  alt="SystemIO"
                  className="max-w-5 max-h-5"
                />
              </Tooltip>
            ) : record?.out_tracker?.mode === "CLIENT_AUTO" ? (
              <Tooltip
                title={
                  "SYSTEM IDLE" +
                  (record?.platform ? " | " + record?.platform : "")
                }
              >
                <img
                  src={ClientAuto}
                  alt="SystemIO"
                  className="max-w-5 max-h-5"
                />
              </Tooltip>
            ) : null}
            <span className="mr-2">
              {dayjs(record.punch_out).format("hh:mm A")}
            </span>
          </div>
        ) : null;
      },
    },
    {
      title: "Duration",
      dataIndex: "punch_duration",
      // sorter: {
      //   compare: (a, b) => a.chinese - b.chinese,
      //   multiple: 3,
      // },
      render: (val, record) => {
        return record.punch_duration != null ? hm(record.punch_duration) : "";
      },
    },
    {
      title: "Logs",
      dataIndex: "view",
      // sorter: {
      //   compare: (a, b) => a.chinese - b.chinese,
      //   multiple: 3,
      // },
      render: (val, record) => {
        return (
          record.punch_in !== null && (
            <Tooltip title={<div>View Punch Logs</div>}>
              <EyeOutlined
                onClick={() =>
                  openPunchLogDateChange(
                    moment(record.date).format("YYYY-MM-DD")
                  )
                }
              />
            </Tooltip>
          )
        );
      },
    },
    // {
    //   title: "Healthy Days",
    //   dataIndex: "healthy_count",
    //   sorter: {
    //     compare: (a, b) => a.chinese - b.chinese,
    //     multiple: 3,
    //   },
    //   width: 100,
    // },
    // {
    //   title: "Overburdened Days",
    //   dataIndex: "overburdened_count",
    //   sorter: {
    //     compare: (a, b) => a.math - b.math,
    //     multiple: 2,
    //   },
    //   width: 100,
    // },
    // {
    //   title: "Underutilized Days",
    //   dataIndex: "underutilized_count",
    //   sorter: {
    //     compare: (a, b) => a.math - b.math,
    //     multiple: 2,
    //   },
    //   width: 100,
    // },

    // {
    //   title: "Healthy %",
    //   dataIndex: "healthyper",
    //   width: 80,

    //   render: (val, record) => {
    //     return (
    //       <>
    //         <Progress
    //           strokeLinecap="butt"
    //           percent={
    //             (record.present_days !== null && record.healthy_count !== 0) ===
    //             true
    //               ? parseFloat(
    //                   (record.healthy_count / record.present_days) * 100
    //                 ).toFixed(2)
    //               : 0
    //           }
    //           strokeColor={"#7B61FF"}
    //         />
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Overburdened %",
    //   dataIndex: "overburdenedper",
    //   width: 80,

    //   render: (val, titem) => {
    //     return (
    //       <>
    //         <Progress
    //           strokeLinecap="butt"
    //           percent={
    //             (titem.present_days !== null &&
    //               titem.overburdened_count !== 0) === true
    //               ? parseFloat(
    //                   (titem.overburdened_count / titem.present_days) * 100
    //                 ).toFixed(2)
    //               : 0
    //           }
    //           strokeColor={"#FFB800"}
    //         />
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Underutilized %",
    //   dataIndex: "underutilizeddper",
    //   width: 80,

    //   render: (val, titem) => {
    //     return (
    //       <>
    //         <Progress
    //           strokeLinecap="butt"
    //           percent={
    //             (titem.present_days !== null &&
    //               titem.underutilized_count !== 0) === true
    //               ? parseFloat(
    //                   (titem.underutilized_count / titem.present_days) * 100
    //                 ).toFixed(2)
    //               : 0
    //           }
    //           strokeColor={"#D5D4DB"}
    //         />
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <div className="charts">
      <div className="chart_header  ml-7">
        <Title level={2}>Detail </Title>
      </div>{" "}
      <Card className="cardpadding_0">
        {attendancedetailed &&
        attendancedetailed.attendance_detailed_attendance_trend_processing ===
          true ? (
          <Spin size="small" />
        ) : AttendanceListData && AttendanceListData.length > 0 ? (
          // null
          <Table
            // rowSelection={rowSelection}
            columns={columns}
            dataSource={AttendanceListData && AttendanceListData}
            // onChange={onChange}
            size="small"
          />
        ) : (
          <div style={{ height: "271px" }} className="emptyblock">
            <Empty description={false} />
          </div>
        )}
      </Card>
      {/* 
<Card className="cardpadding_0">
        {_.size(
          AttendanceListData
        ) > 0 ? (
          <Table
            // rowSelection={rowSelection}
            columns={columns}
            dataSource={
              AttendanceListData &&
              AttendanceListData
            }
            onChange={onChange}
            size="small"
            scroll={{ x: 1500 }}
          />
        ) : (
          <div style={{ height: "271px" }} className="emptyblock">
            <Empty description={false} />
          </div>
        )}
      </Card> */}
      {EmployeeDetail && (
        <Drawer
          open={open}
          // title=""

          onOk={handleOk}
          onClose={handleCancel}
          width={500}
        >
          <AttendaceCalender
            dataemployee={EmployeeDetail && EmployeeDetail}
            open={open}
            type={"detail"}
          />
        </Drawer>
      )}
    </div>
  );
};

export default Attendancetable;
