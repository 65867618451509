import React from "react";
import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import genericDomain from "./genericDomain.json";
import { stringToColor } from "./helper";

export const snakeToCamel = (snakeCaseString: string): string =>
  snakeCaseString.replace(/_./g, (match) => match[1].toUpperCase());

export const copyToClipboard = (
  text,
  showMessage = true,
  successMessage = "URL copied to clipboard",
  failureMessage = "Unable to copy URL to clipboard"
) => {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  textarea.style.position = "fixed";
  document.body.appendChild(textarea);
  textarea.select();

  try {
    const successful = document.execCommand("copy");
    const textMessage = successful ? successMessage : failureMessage;
    if (showMessage) {
      message.success(textMessage);
    }
  } catch (error) {
    console.error("Error copying text to clipboard:", error);
  }

  document.body.removeChild(textarea);
};

export const getSignupTenantType = (email: string) =>
  genericDomain.includes(email) ? "individual" : "organization";

export const antLoadingIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

export const isMacintosh = () => {
  return navigator.platform.indexOf("Mac") > -1;
};

export const isLinux = () => {
  return navigator.appVersion.indexOf("Linux") > -1;
};

export const isStealth = (email: string) =>
  email.endsWith("we360.local") || email.endsWith("we360.ai");

export const sortUserFunc = (identityA, identityB) => {
  const identityAName = `${identityA?.first_name} ${identityA?.last_name}`;
  const identityBName = `${identityB?.first_name} ${identityB?.last_name}`;

  if (identityAName < identityBName) {
    return -1;
  }
  if (identityAName > identityBName) {
    return 1;
  }
  return 0;
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const stringAvatar = (
  firstName = "",
  lastName = "",
  fontSize = "10px"
) => ({
  style: {
    background: stringToColor(`${firstName} ${lastName}`),
    fontSize,
  },
  children: `${firstName.length ? firstName[0].toUpperCase() : ""}${
    lastName.length ? lastName[0].toUpperCase() : ""
  }`,
});
