import { put } from "redux-saga/effects";
import { ManualTimeAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";

export function* fetchManualTimeSaga(action) {
  const queryString = Object.entries(action.payload.body)
    .map(([key, value]) => {
      if (typeof value === "object") {
        return Object.entries(value)
          .map(
            ([subKey, subValue]) =>
              `${key}[${subKey}]=${encodeURIComponent(subValue)}`
          )
          .join("&");
      } else {
        return `${key}=${encodeURIComponent(value)}`;
      }
    })
    .join("&");
  const config = {
    method: "POST",
    url: action.payload.isOwner
      ? `/api/v1/me/hierarchy/time_logs/search/?${queryString}`
      : `/api/v1/me/time_logs/search/`,

    headers: {
      ...action.payload.header,
    },
    data: !action.payload.isOwner ? action.payload.body : {},
  };
  try {
    const response = yield Client.request(config);
    yield put(ManualTimeAction.fetchManualTimeSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ManualTimeAction.fetchManualTimeFailed(errorMessage));
  }
}

export function* createManualTimeSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/time_logs/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(ManualTimeAction.createManualTimeSuccess(response.data));
    notification.success({
      message: `Successfully Created Manual Time!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ManualTimeAction.createManualTimeFailed(errorMessage));
  }
}

export function* updateManualTimeSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/hierarchy/time_logs/${action.payload.id}/approve/?approvalRemarks=${action.payload.approvalRemarks}&isRejected=${action.payload.isRejected}`,

    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);
    yield put(ManualTimeAction.updateManualTimeSuccess(response.data));
    notification.success({
      message: `Status Updated Successfully!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ManualTimeAction.updateManualTimeFailed(errorMessage));
  }
}

export function* deleteManualTimeSaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/me/time_logs/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(ManualTimeAction.deleteManualTimeSuccess());
    notification.success({
      message: `Successfully Deleted ManualTime!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ManualTimeAction.deleteManualTimeFailed(errorMessage));
  }
}
