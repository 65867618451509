import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Layout, Divider, Descriptions, Typography } from "antd";
import "./header.scss";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import Profileinfo from "./profileinfo";
import Companyinfo from "./companyinfo";
import { AuthContext } from "../../contexts/authContext";
import { useDispatch, useSelector } from "react-redux";

const { Paragraph, Text, Title } = Typography;

const ProfileDetail = (props) => {
  const users = useSelector((state) => state.users);
  return (
    <div className="">
      <Profileinfo />
      <Divider />

      <Descriptions layout="vertical">
        <Descriptions.Item label="Date of joining" span={1}>
          {users &&
            users.medetails_data &&
            users.medetails_data.date_of_joining &&
            users.medetails_data.date_of_joining}
        </Descriptions.Item>
        <Descriptions.Item label="Date of Birth">
          {/* 10 Api 1972 */}
          {users &&
            users.medetails_data &&
            users.medetails_data.date_of_birth &&
            users.medetails_data.date_of_birth}
        </Descriptions.Item>
        <Descriptions.Item label="Gender">
          {users &&
            users.medetails_data &&
            users.medetails_data.gender &&
            users.medetails_data.gender}
        </Descriptions.Item>
        <Descriptions.Item label="Phone No">
          {users &&
            users.medetails_data &&
            users.medetails_data.phone_number &&
            users.medetails_data.phone_number}
        </Descriptions.Item>
        <Descriptions.Item label="Team" span={1}>
          {/* Technology -> DevOps{" "} */}
          {users &&
            users.medetails_data &&
            users.medetails_data.attached_group &&
            users.medetails_data.attached_group.name}
        </Descriptions.Item>
        <Descriptions.Item label="Shift" span={1}>
          {/* Morning{" "} */}
          {users &&
            users.medetails_data &&
            users.medetails_data.attached_group &&
            users.medetails_data.attached_group.shift &&
            users.medetails_data.attached_group.shift.name}
        </Descriptions.Item>
      </Descriptions>
      <Divider />

      <Companyinfo />
    </div>
  );
};

ProfileDetail.propTypes = {
  showBackground: PropTypes.bool,
  silderBtn: PropTypes.func,
};

export default ProfileDetail;
