import React, { useContext } from "react";
import { Progress, Space, Button } from "antd";
import { FaUserAlt } from "react-icons/fa";
import { BiUpArrowCircle } from "react-icons/bi";
import { BillingAction } from "redux/store/action";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "contexts/authContext";
import { permissionsMap } from "constants/constant";
const FreeTrail = () => {
  const { subscriptionsData } = useSelector((state) => state.billing);
  const { tenant_list_data } = useSelector((state) => state.tenant);

  const dispatch = useDispatch();
  const { checkRequiredPermissions } = useContext(AuthContext);

  const openUpgradeModal = () => {
    dispatch(BillingAction.onUpgradeModalOpen());
  };

  const getDaysLeft = () =>
    Math.floor(
      (new Date(subscriptionsData.expiryDate).getTime() - Date.now()) / 86400000
    ) + 1;

  const getTotalTrialDaysTillNow = () =>
    tenant_list_data?.created_date
      ? Math.floor(
          (new Date(subscriptionsData.expiryDate).getTime() -
            new Date(tenant_list_data.created_date).getTime()) /
            86400000
        ) + 1
      : 7;

  return (
    <div className="flex items-center m-2 hover:bg-indigo-50 rounded-[3px] px-3 py-1 h-freetrail">
      <Space wrap>
        {subscriptionsData.billingStatus === "IN_TRIAL" && (
          <Progress
            type="circle"
            percent={(getDaysLeft() / getTotalTrialDaysTillNow()) * 100}
            strokeWidth={12}
            width={44}
            format={() => (
              <span className="days">
                {" "}
                {getDaysLeft() + "d"} <br />
                <span>Left</span>
              </span>
            )}
            strokeColor={"#7b61ff"}
          />
        )}
      </Space>
      <div className="flex ml-3 flex-col justify-end cursor-pointer">
        <div className="flex items-center ">
          <div className="text-[14px] font-bold text-indigo-600 leading-4">
            {subscriptionsData.plan.name}
          </div>
          {/* <span className="ml-2 text-slate-600 text-[13px] font-bold">
						2day Left
					</span> */}
        </div>
        <div className="flex items-center justify-end mt-1 text-[12px] text-slate-400">
          <div className="mr-2">
            <FaUserAlt />
          </div>
          {`${subscriptionsData.activeUsers}/${subscriptionsData.totalAllowedUsers}`}
        </div>
      </div>
      {
        checkRequiredPermissions([permissionsMap.TENANT_OWNER]) && (
          // subscriptionsData?.plan?.allow_change && (
          <Button
            type="primary"
            size={"default"}
            icon={<BiUpArrowCircle />}
            className="icon_btn ml-3"
            onClick={openUpgradeModal}
          >
            Upgrade
          </Button>
        )
        // )
      }
    </div>
  );
};

export default FreeTrail;
